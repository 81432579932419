/* WhatsappButton.css */

/* Make sure the chatbox has a fixed height and scrolls */
.floating-whatsapp-chatbox {
    max-height: 400px;  /* Set a maximum height for the chatbox */
    overflow-y: auto;   /* Enable vertical scrolling */
    padding: 1rem;      /* Add padding around the chat content */
  }
  
  /* Control message box content appearance */
  .floating-whatsapp-message {
    font-family: Arial, sans-serif;
    font-size: 14px;
    word-wrap: break-word;
    max-width: 90%;
    padding: 8px;
  }
  
  /* Optional: Scrollbar styles for better UX */
  .floating-whatsapp-chatbox::-webkit-scrollbar {
    width: 3px;
  }
  
  .floating-whatsapp-chatbox::-webkit-scrollbar-thumb {
    background-color: #888;
    
  }
  
  .floating-whatsapp-chatbox::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  