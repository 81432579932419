

.About-Us {
    font-family: 'Roboto Slab', serif;
    font-size: 1.2rem;
    justify-content: center;
    align-items: center; 
    overflow:hidden;

}

.Image-gif {
    position: relative;
    width: 100%;
    height: 150px; /* Desired height */
    background: linear-gradient(180deg, #ade6cd, #3d7b98);
    overflow: hidden; /* Ensure content stays within bounds */
}

.title {
    justify-self: center;
    font-family: 'Roboto', sans-serif;
    position: absolute; /* Position over the image */
    color: white; /* Text color */
    font-size: 3rem; /* Default font size */
    font-weight: bold;
    top: 30%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center text */
}

/* Media Queries */
@media (max-width: 768px) {
    .title {
        font-size: 2.5rem; /* Adjust font size on smaller screens */
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 2rem; /* Further adjust for very small screens */
    }
}
.Heading{
    text-align: center;
    color:#00008b;
    font-weight: bold;
    font-size: 20px;
   
}
.VM {
    display: flex;
    flex-direction: column; /* Change to column for mobile */
    justify-content: center;
    align-items: center; /* Center items */
    gap: 20px; /* Increase gap for better spacing */
}

.card1 {
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 2rem;
    margin: 1rem; /* Space between cards */
    width: 90%; /* Responsive width */
    max-width: 400px; /* Limit maximum width */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    background: linear-gradient(180deg, #ade6cd, #3d7b98);
}

.card1 h2 {
    font-size: 1.5em;
    margin: 0 0 10px;
}

/* Media Queries */
@media (min-width: 768px) {
    .VM {
        flex-direction: row; /* Change back to row for larger screens */
        gap: 10%; /* Adjust gap for larger screens */
    }

    .card1 {
        width: 22.83%; /* Original width for larger screens */
        height: auto; /* Allow height to adjust */
    }
}

.Enterprneur {
    align-items: center; /* Center items vertically */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px; /* Space between sections */
}

.toge {
    justify-content: center; /* Center items */
    width: 100%;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column; /* Stack items for mobile */
}

.ima img {
    width: 100%; /* Make the image responsive */
    max-width: 300px; /* Limit max width */
    border-radius: 8px; /* Rounded corners */
    object-fit: cover;
}

.tex {
    border: 1px solid #ddd;
    font-size: 1rem;
    border-radius: 15px;
    width: 100%; /* Full width on mobile */
    max-width: 600px; /* Limit max width */
    background: linear-gradient(180deg, #ade6cd, #3d7b98);
    color: black;
    padding: 20px;
    margin: 20px 0; /* Space between text and image */
}

.asn img{
    width: 100%; 
    border-radius: 8px; 
    
   
    width:28.3rem;
    object-fit: cover;

}

/* Media Queries */
@media (min-width: 768px) {
    .toge {
        flex-direction: row; /* Change back to row for larger screens */
        justify-content: space-around; /* Space items evenly */
    }

    .ima img {
        width: 100%; /* Original width for larger screens */
    }

    .tex {
        width: 49.6%; /* Original width for larger screens */
    }

    
}


.fixed h1{color: #fbf9f9;
        width:40%;
        height:50px;
        
        background-color: rgba(56, 42, 42, 0.7);
    }
.Fixed{
   
    text-align: center;
    opacity:80%;
    background-attachment: fixed;
    background-image:url("../images/processed.jpeg");
    background-color: rgba(77, 63, 63, 0.1);
    
    background-repeat: no-repeat;
    background-size: cover;
    height: 55rem;
    margin-top: 2rem;
    padding-bottom: 6rem;
    padding-top: 2rem;
}

.first{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.White-Card{
    border: 1px solid linear-gradient(180deg, #ade6cd, #3d7b98);
    border-radius: 15px;
    width:400px;
    height: 210px;
    margin:40px;
    padding:10px;
    text-align: center;
    align-items: center;
    /* background: linear-gradient(180deg, #ade6cd, #3d7b98); */
    background:white
}
.White-Card h3{
 align-self: center;
}
.icon-img{
    margin-top: 40px;
    justify-content: center;
    align-items: center;
}
.icon-img img{
    height:60.33px;
    width:60.33px;
}

.last{
    border: 2px solid linear-gradient(180deg, #ade6cd, #3d7b98);
    border-radius: 15px;
    width:400px;
    padding:10px;
    margin-left: 36.2%;
    height: 210px;
    background:white
}
.ico{
    margin-top: 30px;
    justify-content: center;
    align-items: center;
}
.ico img{
    height:60.33px;
    width:60.33px;
}
@media (max-width: 768px) {
    .Fixed {
        padding: 2rem; /* Reduce padding for smaller screens */
        height: auto; /* Allow height to adjust based on content */
    }

    .fixed h1 {
        width: 100%; /* Make the title full width */
        font-size: 2rem; /* Adjust font size for mobile */
    }

    .first {
        flex-direction: column; /* Stack cards vertically */
        align-items: center; /* Center align items */
    }

    .White-Card {
        width: 90%; /* Make cards responsive */
        margin: 10px 0; /* Space between cards */
        max-width: 400px; /* Limit the max width */
    }

    .last {
        width: 90%; /* Make last card responsive */
        margin: 10px auto; /* Center it */
        max-width: 400px; /* Limit the max width */
    }

    .icon-img img {
        height: 40px; /* Adjust icon size for mobile */
        width: 40px;
    }
}



.ref-par{
    
    height: 270px;
    margin:30px
}
.adv{
    display:flex;
    flex-direction: row;
    margin-left:7%;
}
.adv img{
    height: 64px;
    width:64px;
}
.adv-cart{
    font-size: 1rem;
    border: rgb(234, 228, 228) 0.1px solid;
    padding:15px;
    margin-top: 20px;
    width:220px;
    height:142px;
    align-items: center;
    text-align: center;
}
@media (max-width: 768px) {
    .ref-par {
        height: auto; /* Allow height to adjust based on content */
        margin: 20px; /* Reduce margin */
    }
    
    .adv {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items */
        margin-left: 0; /* Reset margin */
    }
    
    .adv-cart {
        width: 90%; /* Make cards responsive */
        max-width: 300px; /* Limit max width */
        margin: 10px 0; /* Space between cards */
    }
    
    .adv img {
        height: 48px; /* Adjust icon size for mobile */
        width: 48px;
    }
    
    .Heading h1 {
        font-size: 1.5rem; /* Adjust title size for mobile */
        text-align: center; /* Center the title */
    }
}

.grow{
    background: linear-gradient(180deg, #ade6cd, #3d7b98);
    align-items: centre;
    text-align: center;
    height: 11rem;
    padding: 10px;
}
.contU{
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: white;
    color: black;
    text-decoration: none;
    border-radius: 20px;
}
