/* Default styles for the header */
.header {
    margin-bottom: 0;
    width: 100%;
    overflow: hidden;
}

.contact-info {
    padding: 10px;
    align-items: center;
    gap: 12rem;
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    background: #003e71;
}

.header-item {
    display: flex; 
    padding-left: 7.5rem;
    align-items: center; 
    color: white;
    font-size: 16px;
    margin: 5px 0; /* Add margin for spacing */
}

.header-item i {
    margin-right: 8px;
}

.nav-container {
    justify-content: space-around;
    display: flex; 
    height: 7rem;
    flex-direction: row; /* Stack logo and nav vertically */
    align-items: center; 
    width: 100%; 
}

.logo img {
    height: 150px; 
    width: auto; 
    padding-left: 150px;
}

.navigation {
    display: flex; /* Initially, the navigation is a row */
    max-width: 1500px;
    width: 100%; 
    margin-left: 100px;
    transition: transform 0.3s ease-in-out, right 0.3s ease-in-out; /* Smooth transition */
}

.navigation ul {
    display: flex; 
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: center; /* Center items */
    list-style: none; 
    padding: 0; 
    margin: 10px 0; /* Adjust margin */
}

.navigation li {
    margin: 1rem; /* Smaller margins for mobile */
    position: relative;
}

.navigation a {
    font-size: 18px; /* Smaller font size for mobile */
    color: #003e71; 
    text-decoration: none; 
    padding: 5px; 
    transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease; 
}

select {
    border: none; 
    font-size: 18px;
    color: #003e71;
    cursor: pointer;
}

select:hover {
    color: #4faaa4; 
}

.navigation a::after {
    content: "";
    position: absolute; 
    left: 0;
    bottom: 0;
    width: 100%; 
    background: #003e71; 
}

.navigation a:hover {
    color: #4faaa4; 
}

option {
    font-size: 18px; /* Smaller font size for mobile */
    color: #003e71;
    text-decoration: none; 
    padding: 1rem;
    padding: 5px; 
    transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease; 
}

option::after {
    content: "";
    position: absolute; 
    left: 0;
    bottom: 0;
    width: 100%; 
    background: #003e71; 
}

option:hover {
    color: #4faaa4; 
}

/* Mobile styles */
@media (max-width: 600px) {
    .header-item {
        padding-left: 0rem;
    }

    .contact-info {
        gap: 0.1rem;
        flex-direction: column; /* Stack items vertically */
        align-items: flex-start; /* Align items to the start */
    }

    .nav-container {
        margin-top: 10px; /* Add space between sections */
    }
    
    .navigation {
        margin-left: 20px;
    }

    .logo img {
        height: 100px;
        padding-left: 0px;
    }

    .navigation a {
        font-size: 16px; /* Further reduce font size */
    }

    .navigation ul {
        flex-direction: column; /* Stack nav items */
        align-items: center; /* Center align */
    }

    /* Burger icon style */
    .burger {
        display: block; /* Show the burger menu button on mobile */
        background: transparent;
        border: none;
        cursor: pointer;
        font-size: 24px; /* Size of the burger icon */
        color: rgb(16, 15, 15); /* Color remains the same */
        z-index: 1000; /* Ensure burger icon is above other content */
    }

    /* Initially hide the navigation menu */
    .navigation {
        position: fixed; /* Fix position to the right */
        top: 37%;
        padding-top: -3rem;
        right: -100%; /* Initially hide the menu off-screen */
        width: 55%; /* Adjust width as needed */
        height: 23rem; /* Full height of the viewport */
        background: linear-gradient(180deg, #add8e6, #38389c); /* Background color */
        z-index: 999; /* Ensure it is above other content */
        transition: right 0.3s ease-in-out; /* Smooth transition for slide-in */
    }

    /* When the navigation is open, slide in from the right */
    .navigation.open {
        right: 0; /* Slide to the right */
    }

    /* When scrolling down, hide the navigation */
    .navigation.hide {
        right: -100%; /* Slide out of view */
    }

    .navigation ul {
        flex-direction: column; /* Stack nav items */
        align-items: center;
        margin-top: 60px; /* Adjust for the logo height */
    }
}

/* Desktop styles - Hide the burger menu on screens larger than 600px */
@media (min-width: 601px) {
    .burger {
        display: none; /* Hide the burger menu button on desktop */
    }

    /* Make sure the navigation menu is visible on desktop */
    .navigation {
        position: static; /* Keep the navigation in the normal flow */
        height: auto; /* Allow the height to adjust dynamically */
        background: none; /* Remove the background */
        transition: none; /* Remove transition for desktop */
    }

    .navigation ul {
        flex-direction: row; /* Horizontal layout */
        align-items: center; /* Center align */
    }
}
