.footer {
    background-color: #103a52;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1rem;
    position: relative;
    width: 100%;
    height: auto; /* Maintain original height */
    overflow: hidden;
    
    
}

.Together {
   
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
   
}

.contact {
   
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    height: 14rem;
    margin-left: 80px;
    color:white;
    padding: 1rem;
    width: 40%; /* Maintain width for desktop */
    max-width: 600px;
    margin: 60px; /* Center align the contact box */
    font-size: large;
    margin-top: 1rem;
    
}

.texy {
    display: flex;
    flex-direction: row;
    width: 1200px; /* Keep original width */
    margin-left: 0px;
    height: 500px;   
}

.Quick-Links a{
    text-decoration: none;
    color: white;
}

.tog-lis.highlighted {
    background-color: #f0f0f0; 
    border: 2px solid #ffcc00; 
   
  }
  
  

.Quick-Links, .Services {
    margin-top: 10px;
    margin-left: 50px;
    padding: 10px;
   
    color: white;
    width: 150px; /* Maintain width for desktop */
    align-items: center;
    text-align: center;
}

.social {
    display: flex;
    justify-self: left;
    flex-direction: row;
    gap: 1rem;
}
.darr i{
    margin-top: 1.5rem;
}
 
.sub-cont{
    display: flex;
}

.Addcol{
    margin-left: 2rem;
   margin-top:-1.6rem;
}

.Addcol1{
    margin-left: 2.4rem;
}

.icon{
    margin-top: 1rem;
}

.Services{
    margin-left: 10rem;
 width:30rem;
 height: 15rem;
}

.sub-cont i{
    margin-right:-1rem ;
}
.tog-lis{
    display: flex;
    gap:2rem;
    margin-top:-1rem;

}
.Services h2{
   margin-right: 9rem;
}
.my-image {
    height: 24px;
    width: 24px;
}
.image-size {
    height: 12px; /* Keep original GIF size */
    width: 20px; /* Keep original GIF size */
}
.Copyright {
    border-top:2px white solid;
    text-align: center;
    color: white;
    margin-top: -12rem;
}
@media (max-width: 768px) {
    .footer {
        height: auto; /* Allow height to adjust */
    }

    .Together {
        flex-direction: column; /* Stack items vertically on mobile */
        align-items: center; /* Center align */
    }

    .contact {
        width: 90%; /* Full width for mobile */
        margin: 20px auto; /* Center align */
    }

    .texy {
        flex-direction: column; /* Stack service links vertically */
        width: 100%; /* Full width for mobile */
        margin: 0; /* Reset margin */
        height: auto; /* Allow height to adjust */
    }

    .tog-lis{
   
        display: flex;
        align-content: center;
        margin-left: 4rem;
       
    }
    .Services h2{
        margin-right: 0rem;
     }

    .Quick-Links
     {
        width: 90%; /* Full width for links and services */
        margin-left: 0; /* Remove left margin */
        text-align: center; /* Center align text */
        margin-top: 10px; /* Add spacing */
        justify-content: center;
        
    }

    .sub-cont{
        display: flex;
    }
    .Services{
        justify-content: space-around;
        width:100%;
        text-align: center;
        margin-left: -24px;
       
    }
   
    .Quick-Links ul,
    .Services ul {
        list-style-type: none;
        padding: 0; /* Remove padding */
    }

    .Quick-Links li,
    .Services li {
        margin: 5px 0; /* Add vertical spacing */
    }

    .my-image {
        height: 24px; /* Keep original image size */
        width: 24px; /* Keep original image size */
    }

    .image-size {
        height: 12px; /* Keep original GIF size */
        width: 20px; /* Keep original GIF size */
    }

    .Copyright {
        text-align: center;
        color: white;
        margin-top:10px;
    }
}
