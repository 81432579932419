    .emi-calculator {
        width: 500px;
        margin: auto;
        padding: 20px;
        
        border-radius: 8px;
        padding:20px 15px;
    
    }
    
   
    
    .input-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    
    .input-item {
        width: 100%; /* Adjust width for even spacing */
    }
    
    .input-item label {
        display: block;
        margin-bottom: 5px;
    }
    
    
    
    .calculate-button {
        width: 100%;
 
        padding: .6rem;
        font-size: .9rem;
        margin-top: 20px;
        background-color: #00008b;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
       
        border-radius: 4px;
        cursor: pointer ;
    }
    
    
    .result {
        margin-top: 20px;
        text-align: center;
        font-size: .9rem;
        text-align: left;
        padding: 10px;
        background-color: #e1e3fc;
        border: 1px solid  #e1e3fc;
        border-radius: 7px;
    }
    
    .input-item input {
        width: 80%;
        padding: 8px;
        border: 1px solid #ccc;
        height: 25px;
        border-radius: 7px;
        background-color: white; /* Default background color */
        transition: background-color 0.3s; /* Optional: add transition for smooth effect */
    }
    .input-item label{
        color:#4488d1;
        font-size:.8rem;
    }